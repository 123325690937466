import { READ_OFFICES_SELECT } from "../../actionTypes";
const initialState = {
  forSelect: [],
}
// const history = useHistory();

const officeReducers = (state = initialState, action) => {
  switch (action.type) {
    case READ_OFFICES_SELECT:
      return {
        ...state,
        forSelect: action.payload,
      }
    default:
      return state
  }
}

export default officeReducers
