// ** Redux Imports
import { combineReducers } from 'redux'
// ** Reducers Imports
import auth from './auth'
import clients from './clients'
import customDynamicSurvey from './customDynamicSurvey'
import dataAuditReducer from './data-audit'
import delinquencyManagementReducer from './delinquencyManagement'
import disbursementClientReducer from './disbursement'
import layout from './layout'
import messageTemplateReducer from './messageTemplate'
import navbar from './navbar'
import notificationReducer from './notification'
import offices from './offices'
import publicHolidayReducer from './publicHoliday'
import replyMessageReducer from './replyMessage'
import scheduleTemplateReducer from './scheduleTemplate'
import staffs from './staffs'
import uiConfig from './uiConfig'



const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  customDynamicSurvey,
  offices,
  staffs,
  clients,
  publicHolidayReducer,
  uiConfig,
  scheduleTemplateReducer,
  messageTemplateReducer,
  disbursementClientReducer,
  notificationReducer,
  replyMessageReducer,
  delinquencyManagementReducer,
  dataAuditReducer,
})

export default rootReducer
