/**
 * Feature      - Reply Message Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import { GET_ALL_REPLY_MESSAGE, RESEND_MESSAGE } from "../../actionTypes";

// ** Initial data
const initialState = {
  replyMessageList: null
}

const replyMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REPLY_MESSAGE:
      return {
        ...state,
        replyMessageList: action.payload
      }
    case RESEND_MESSAGE:
      return {
        ...state,
        resendReplyMessage: action.payload
      }
    default:
      return state
  }
}

export default replyMessageReducer;
