import { ACTIVE_SURVEY, EXPORT_PROGRESS_DATA, LOAD_QUESTION_SET, PREPARE_SUREVEY_TASK_TABLE, PROGRESS_DATA, READ_ALL_SURVEY, READ_ALL_SURVEY_MY_LIST, READ_ALL_SURVEY_TASK } from "../../actionTypes";
const initialState = {
  activeSurvey: {},
  surveyList: [],
  surveyTaskList: [],
  curentQuestions: [],
  surveyTaskTable: [],
  surveyListMyList: [],
  currentQuestionSet: [],
  surveyListPagination: {
    rowsOptions: [10, 50, 100, 200, 400, 800, 1600],
    totalRows: 10,
    currentPage: 1,
    rowPerPage: 10,
    pageCount: 1,
  },
  surveyListMyListPagination: {
    rowsOptions: [10, 50, 100, 200, 400, 800, 1600],
    totalRows: 10,
    currentPage: 1,
    rowPerPage: 10,
    pageCount: 1,
  },
  surveyTaskPagination: {
    rowsOptions: [10, 50, 100, 200, 400, 800, 1600],
    totalRows: 10,
    currentPage: 1,
    rowPerPage: 10,
    pageCount: 1,
  },
  progressData: [],
  exportProgressData: []
}
// const history = useHistory();

const customDynamicSurveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_SURVEY:
      return {
        ...state,
        activeSurvey: action.payload,
      }
    case READ_ALL_SURVEY:
      const getDataAll = action.payload;
      const paginationDataAll = state.surveyListPagination;
      const rowsOptionsAll = [];
      paginationDataAll.totalRows = getDataAll.total;
      paginationDataAll.currentPage = getDataAll.current_page;
      paginationDataAll.rowPerPage = getDataAll.per_page;
      paginationDataAll.pageCount = getDataAll.total / getDataAll.per_page || 1;
      paginationDataAll.rowsOptions.map((data) => {
        if (data < paginationDataAll.totalRows) {
          return rowsOptionsAll.push(data);
        }
      })
      rowsOptionsAll.push(getDataAll.total);
      paginationDataAll.rowsOptions = rowsOptionsAll;
      return {
        ...state,
        surveyList: getDataAll.data,
        surveyListPagination: paginationDataAll
      }
    case READ_ALL_SURVEY_TASK:
      const getData = action.payload;
      const paginationData = state.surveyTaskPagination;
      const rowsOptions = [];
      paginationData.totalRows = getData.total;
      paginationData.currentPage = getData.current_page;
      paginationData.rowPerPage = getData.per_page;
      paginationData.pageCount = getData.total / getData.per_page || 1;
      paginationData.rowsOptions.map((data) => {
        if (data < paginationData.totalRows) {
          return rowsOptions.push(data);
        }
      })
      rowsOptions.push(getData.total);
      paginationData.rowsOptions = rowsOptions;
      return {
        ...state,
        surveyTaskList: getData.data,
        surveyTaskPagination: paginationData
      }
    case READ_ALL_SURVEY_MY_LIST:
      const getDataMyList = action.payload;
      const paginationDataMyList = state.surveyListMyListPagination;
      const rowsOptionsMyList = [];
      paginationDataMyList.totalRows = getDataMyList.total;
      paginationDataMyList.currentPage = getDataMyList.current_page;
      paginationDataMyList.rowPerPage = getDataMyList.per_page;
      paginationDataMyList.pageCount = getDataMyList.total / getDataMyList.per_page || 1;
      paginationDataMyList.rowsOptions.map((data) => {
        if (data < paginationDataMyList.totalRows) {
          return rowsOptionsMyList.push(data);
        }
      })
      rowsOptionsMyList.push(getDataMyList.total);
      paginationDataMyList.rowsOptions = rowsOptionsMyList;
      return {
        ...state,
        surveyListMyList: getDataMyList.data,
        surveyListMyListPagination: paginationDataMyList
      }
    case LOAD_QUESTION_SET:
      const data = action.payload;
      data.map((data) => {
        if (data.options !== "") {
          data['options'] = JSON.parse(data.options)
        }
      })
      return {
        ...state,
        curentQuestions: data
      }
    case PREPARE_SUREVEY_TASK_TABLE:
      const actionData = action.payload;
      const surveyTaskTable = [];
      const currentQuestion = [];
      actionData.map((data, index) => {
        currentQuestion.push(JSON.parse(data.question_data))
        if (data.question !== "" && data.question_id !== "") {
          surveyTaskTable.push({
            name: data.question,
            selector: data.question_id,
            sortable: false,
            minWidth: '400px',
            cell: (row, index, column) => {
              const base64Rejex = /^data:image\/[a-z]+;base64,/;
              const value = row[column.selector];
              return (
                base64Rejex.test(value) ? (
                  <img src={value} />
                ) : value
              )
            }
          })
        }
      })
      return {
        ...state,
        surveyTaskTable: surveyTaskTable,
        currentQuestionSet: currentQuestion
      }
    case PROGRESS_DATA:
      return {
        ...state,
        progressData: action.payload,
      }
    case EXPORT_PROGRESS_DATA:
      return {
        ...state,
        exportProgressData: action.payload,
      }
    default:
      return state
  }
}

export default customDynamicSurveyReducer
