/**
 * Feature      - Notification Reducer
 * Developer    - Aung Htet Paing
*/

// ** Import redux action type
import { READ_ALL_NOTIFICATION } from "../../actionTypes";

// ** Initial data
const initialState = {
  notificationList: []
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ALL_NOTIFICATION:
      return {
        ...state,
        notificationList: action.payload
      }
    default:
      return state
  }
}

export default notificationReducer;
