/**
 * Feature      - Message Template Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import { READ_ALL_MESSAGE_TEMPLATE, READ_DETAIL_MESSAGE_TEMPLATE } from "../../actionTypes";

// ** Initial data
const initialState = {
  messageTemplateList: [],
  detailMessageTemplate: null
}

const messageTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ALL_MESSAGE_TEMPLATE:
      return {
        ...state,
        messageTemplateList: action.payload
      }
    case READ_DETAIL_MESSAGE_TEMPLATE:
      return {
        ...state,
        detailMessageTemplate: action.payload
      }
    default:
      return state
  }
}

export default messageTemplateReducer;
