import { GET_LOADING, SET_LOADING } from "../../actionTypes";
const initialState = {
  loadingState: {
  },
}
// const history = useHistory();

const UiConfigReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      const loading = state.loadingState
      loading[action.payload[0]] = action.payload[1]
      return {
        ...state,
        loadingState: loading,
      }
    default:
      return state
  }
}

export default UiConfigReducers
