/**
 * Feature      - Disbursement Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import {
  DDFR_SEARCH,
  FILTER_DDFR_REPORTS,
  GET_DDFR_LIST,
  GET_DDFR_REPORT,
  SAVE_DDFR_FORM,
  SET_DDFR_LOADING,
  SET_DDFR_REPORT_LOADING,
  SET_DDFR_SEARCH_VALUES,
  TOGGLE_DDFR_DETAIL,
  TOGGLE_DDFR_FORM,
  TOGGLE_DDFR_REPORT_FILTER,
  TOGGLE_DDFR_SEARCH,
  SET_DDFR_REPORT_SEARCH_VALUES,
} from "../../actionTypes";

// ** Initial data
const initialState = {
  ddfr: {
    ddfrLoading: true,
    ddfrs: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      row_total: 0,
    },
    filteredDdfrs: [],
    DDFRDetail: { isOpen: false, data: null },
    ddfrForm: {
      isOpen: false,
      ddfr: {},
    },
    search: {
      isOpen: false,
      fo_names: [],
      root_causes: [],
      follow_up_results: [],
      loan_types: [],
      offices: [],
    },
    searchValues: {
      created_at: null,
      next_follow_up_date: null,
      loan_id: null,
      loan_type: null,
      client_id: null,
      root_cause: null,
      follow_up_result: null,
      fo_name: null,
      fo_id: null,
      aging: null,
      address: null,
    },
  },

  ddfrReport: {
    loading: true,
    isFilterOpen: false,
    searchValues: {
      client_id: "",
      staff_id: "",
      staff_name: "",
      need_to_fill_ddfr: "",
      office: "",
    },
    list: {
      offices: [],
      fo_names: [],
      need_to_fill_ddfr: 0,
      ddfr_done: 0,
      source: [],
      data: [],
    },
  },
};

const delinquencyManagementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_DDFR_LOADING:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ddfrLoading: payload,
        },
      };
    case GET_DDFR_LIST:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ...payload,
          ddfrLoading: false,
        },
      };
    case TOGGLE_DDFR_DETAIL:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ...payload,
        },
      };
    case TOGGLE_DDFR_FORM:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ...payload,
        },
      };
    case SAVE_DDFR_FORM:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ...payload,
          // ddfrLoading: true
        },
      };
    case TOGGLE_DDFR_SEARCH:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          search: { ...state.ddfr.search, isOpen: payload },
        },
      };
    case DDFR_SEARCH:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          ...payload,
          search: {
            ...state.ddfr.search,
            isOpen: false,
          },
        },
      };

    case SET_DDFR_SEARCH_VALUES:
      return {
        ...state,
        ddfr: {
          ...state.ddfr,
          searchValues: { ...payload, isSearch: false },
        },
      };

    case SET_DDFR_REPORT_SEARCH_VALUES:
      return {
        ...state,
        ddfrReport: {
          ...state.ddfrReport,
          searchValues: { ...payload },
        },
      };

    case SET_DDFR_REPORT_LOADING:
      return {
        ...state,
        ddfrReport: {
          ...state.ddfrReport,
          loading: payload,
        },
      };

    case TOGGLE_DDFR_REPORT_FILTER:
      return {
        ...state,
        ddfrReport: {
          ...state.ddfrReport,
          isFilterOpen: payload,
        },
      };

    case GET_DDFR_REPORT:
      return {
        ...state,
        ddfrReport: {
          ...state.ddfrReport,
          loading: false,
          isFilterOpen: false,
          list: payload,
        },
      };
    case FILTER_DDFR_REPORTS:
      return {
        ...state,
        ddfrReport: {
          ...state.ddfrReport,
          isFilterOpen: false,
          list: {
            ...state.ddfrReport.list,
            ...payload,
          },
        },
      };
    default:
      return state;
  }
};

export default delinquencyManagementReducer;
