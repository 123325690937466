/**
 * Feature      - Disbursement Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import {
    FILTER_DATA_AUDIT, GET_DATA_AUDIT_LIST, GET_DATA_AUDIT_PREDEFINED_DATA, SET_DATA_AUDIT_LOADING, TOGGLE_DATA_AUDIT_DETAIL, TOGGLE_DATA_AUDIT_FILTER
} from "../../actionTypes";

// ** Initial data
const initialState = {
    loading: true,
    isFilterOpen: false,
    data: [],
    predefined: {
        actions: [
            { value: 'create', label: 'create' },
            { value: 'update', label: 'update' },
            { value: 'delete', label: 'delete' },
        ],
        sources: [
            { value: 'field-app', label: 'Field App' },
            { value: 'dashboard', label: 'Dashboard' },
        ]
    },
    filter: {
        staff_id: null,
        action: null,
        table_name: null,
        source: null,
        created_at: null,
        data: null,
    },
    detail: {
        isOpen: false,
        data: []
    }
}

const dataAuditReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_DATA_AUDIT_LOADING:
            return {
                ...state,
                loading: payload,
            }
        case GET_DATA_AUDIT_LIST:
            return {
                ...state,
                data: payload,
                loading: false,
            }
        case TOGGLE_DATA_AUDIT_FILTER:
            return {
                ...state,
                isFilterOpen: payload
            }
        case GET_DATA_AUDIT_PREDEFINED_DATA:
            return {
                ...state,
                predefined: { ...state.predefined, ...payload }
            }
        case FILTER_DATA_AUDIT:
            return {
                ...state,
                filter: payload
            }
        case TOGGLE_DATA_AUDIT_DETAIL:
            return {
                ...state,
                detail: payload
            }
        default:
            return state
    }
}

export default dataAuditReducer;
