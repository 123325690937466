export const LOGIN_EVENT = "LOGIN_EVENT";
export const LOGOUT_EVENT = "LOGOUT_EVENT";

// Custom Dynamic Survey
export const CREATE_SURVEY = "CREATE_SURVEY";
export const READ_ALL_SURVEY = "READ_ALL_SURVEY";
export const READ_ALL_SURVEY_MY_LIST = "READ_ALL_SURVEY_MY_LIST";
export const ACTIVE_SURVEY = "ACTIVE_SURVEY";

// Office Data
export const READ_OFFICES_SELECT = "READ_OFFICES_SELECT";

// Staffs Data
export const READ_STAFFS_SELECT = "READ_STAFFS_SELECT";

// Clients Data
export const READ_CLIENTS_SELECT = "READ_CLIENTS_SELECT";

// Custom Dynamic Survey
export const CREATE_SURVEY_TASK = "CREATE_SURVEY_TASK";
export const READ_ALL_SURVEY_TASK = "READ_ALL_SURVEY_TASK";
export const ACTIVE_SURVEY_TASK = "ACTIVE_SURVEY_TASK";
export const PROGRESS_DATA = "PROGRESS_DATA";
export const EXPORT_PROGRESS_DATA = "EXPORT_PROGRESS_DATA";
export const LOAD_QUESTION_SET = "LOAD_QUESTION_SET";

/**
 * Feature      - Public Holiday
 * Developer    - Aung Htet Paing
 */

// ** Public Holiday
export const READ_ALL_PUBLIC_HOLIDAY = "READ_ALL_PUBLIC_HOLIDAY";
export const UPDATE_PUBLIC_HOLIDAY_LIST = "UPDATE_PUBLIC_HOLIDAY_LIST";
export const UPDATE_PUBLIC_HOLIDAY_DATA = "UPDATE_PUBLIC_HOLIDAY_DATA";
export const PREPARE_SUREVEY_TASK_TABLE = "PREPARE_SUREVEY_TASK_TABLE";

// ** Schedule Template
export const READ_ALL_SHCEDULE_TEMPLATE = "READ_ALL_SHCEDULE_TEMPLATE";
export const TOGGLE_SCHEDULE_TEMPLATE_LOADING = "TOGGLE_SCHEDULE_TEMPLATE_LOADING";
export const DELETE_SCHEDULE_TEMPLATE = "DELETE_SCHEDULE_TEMPLATE";
export const UPDATE_SCHEDULE_TEMPLATE = "UPDATE_SCHEDULE_TEMPLATE";

// ** Message Template
export const READ_ALL_MESSAGE_TEMPLATE = "READ ALL MESSAGE_TEMPLATE";
export const READ_DETAIL_MESSAGE_TEMPLATE = "READ_DETAIL_MESSAGE_TEMPLATE";

// ** Disbursement Client
export const READ_ALL_DISBURSEMENT_CLIENT = "READ_ALL_DISBURSEMENT_CLIENT";

// ** Notification
export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION";

// ** Reply Message
export const GET_ALL_REPLY_MESSAGE = "GET_ALL_REPLY_MESSAGE";
export const RESEND_MESSAGE = "RESEND_MESSAGE";

export const SET_LOADING = "SET_LOADING";
export const GET_LOADING = "GET_LOADING";

// Delinquency Management
export const SET_DDFR_LOADING = "SET_DDFR_LOADING";
export const GET_DDFR_LIST = "GET_DDFR_LIST";
export const TOGGLE_DDFR_DETAIL = "TOGGLE_DDFR_DETAIL";
export const TOGGLE_DDFR_SEARCH = "TOOGLE_DDFR_SEARCH";
export const DDFR_SEARCH = "DDFR_SEARCH";
export const TOGGLE_DDFR_FORM = "TOGGLE_DDFR_FORM";
export const SAVE_DDFR_FORM = "SAVE_DDFR_FORM";
export const SET_DDFR_REPORT_LOADING = "SET_DDFR_REPORT_LOADING";
export const TOGGLE_DDFR_REPORT_FILTER = "TOGGLE_DDFR_REPORT_FILTER";
export const GET_DDFR_REPORT = "GET_DDFR_REPORT";
export const FILTER_DDFR_REPORTS = "FILTER_DDFR_REPORTS";
export const SET_DDFR_SEARCH_VALUES = "SET_DDFR_SEARCH_VALUES";
export const SET_DDFR_REPORT_SEARCH_VALUES = "SET_DDFR_REPORT_SEARCH_VALUES";

//DATA AUDIT LOG
export const GET_DATA_AUDIT_LIST = "GET_DATA_AUDIT_LIST";
export const SET_DATA_AUDIT_LOADING = "SET_DATA_AUDIT_LOADING";
export const TOGGLE_DATA_AUDIT_FILTER = "TOGGLE_DATA_AUDIT_FILTER";
export const GET_DATA_AUDIT_PREDEFINED_DATA = "GET_DATA_AUDIT_PREDEFINED_DATA";
export const FILTER_DATA_AUDIT = "FILTER_DATA_AUDIT";
export const TOGGLE_DATA_AUDIT_DETAIL = "TOGGLE_DATA_AUDIT_DETAIL";
