import axios from 'axios';
import env from "react-dotenv";
import history from "./history";

axios.defaults.baseURL = `${env.API_URL}/${env.PRE_FIX}/${env.VERSION}`;

export const axiosSteup = () => {
  axios.defaults.baseURL = `${env.API_URL}/${env.PRE_FIX}/${env.VERSION}`;
  // axios.defaults.baseURL = 'https://api.hanawebapp.uat.hanamicrofinance.net/api
  axios.interceptors.request.use(
    (req) => {
      req.headers["Access-Control-Allow-Origin"] = "*";
      req.headers["Access-Control-Allow-Methods"] = "GET, POST, PATCH, PUT, DELETE, OPTIONS";
      req.headers["Access-Control-Allow-Headers"] = "*";
      req.headers["appType"] = "web_app";
      // console.log(req);
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (result) => result,
    (error) => {
      if (error && error.response && error.response.status === 401) {
        history.push("/not-authorized");
        history.go(0)
      }
      return Promise.reject(error);
    }
  );
}
export const httpErrorHandler = async (error) => {
  if (error.response === undefined) {
    return {
      error: { title: 'Network Error', message: 'Please check your internet connection' }
    }
  }

  if (error && error.response && error.response.status === 401) {
    return {
      error: { title: 'Unauthorized', message: 'Invalid Token' }
    }
  }

  if (error && error.response && error.response.status === 404) {
    return {
      error: { title: 'Not Found', message: 'Resource not found' }
    }
  }

  if (error && error.response && error.response.status === 422) {
    return {
      error: { title: 'Internal Srever Error', message: 'Something was wrong' }
    }
  }
  if (error && error.response && error.response.status === 409) {
    return {
      error: { title: 'Internal Srever Error', message: 'Something was wrong' }
    }
  }

  if (error && error.response && error.response.status === 400) {
    return {
      error: {
        title: error.response.data.status, message: error.response.data.message
      }
    }
  }

  if (error && error.response && error.response.status === 500) {
    return error;
  }
}

/**
 * 
 * @param {*} url 
 * @returns 
 */

const options = {
  onDownloadProgress: (progressEvent) => {
    let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total * 100)
    console.log(`load ${progressEvent.loaded} | Total ${progressEvent.total} : ${percentCompleted}`)
  }
}
export const getRequest = async (url, params) => {
  return await axios.get(url, { params: { ...params } }).then((result) => {
    return result.data;
  }, (error) => {
    return httpErrorHandler(error);
  });
}

/**
 * 
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
export const postRequest = async (url, data) => {
  return await axios.post(url, data).then((result) => {
    return result.data;
  }, (error) => {
    return httpErrorHandler(error);
  });
}

export const putRequest = async (url, data) => {
  return await axios.put(url, data).then((result) => {
    return result.data;
  }, (error) => {
    return httpErrorHandler(error);
  });
}

export const deleteRequest = async (url) => {
  return axios.delete(url).then((result) => {
    return result.data;
  }, (error) => {
    return httpErrorHandler(error);
  });
};
