/**
 * Feature      - Disbursement Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import { READ_ALL_DISBURSEMENT_CLIENT } from "../../actionTypes";

// ** Initial data
const initialState = {
  disbursementClientList: []
}

const disbursementClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ALL_DISBURSEMENT_CLIENT:
      return {
        ...state,
        disbursementClientList: action.payload
      }
    default:
      return state
  }
}

export default disbursementClientReducer;
