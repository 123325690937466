/**
 * Feature      - Public Holiday Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import { READ_ALL_PUBLIC_HOLIDAY } from "../../actionTypes";

// ** Initial data
const initialState = {
  publicHolidayList: []
}

const publicHolidayReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ALL_PUBLIC_HOLIDAY:
      return {
        ...state,
        publicHolidayList: action.payload
      }
    default:
      return state
  }
}

export default publicHolidayReducer;
