/**
 * Feature      - Schedule Template Reducer
 * Developer    - Aung Htet Paing
 */

// ** Import redux action type
import { READ_ALL_SHCEDULE_TEMPLATE, TOGGLE_SCHEDULE_TEMPLATE_LOADING } from "../../actionTypes";

// ** Initial data
const initialState = {
  scheduleList: [],
  loading: true,
}

const scheduleTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case READ_ALL_SHCEDULE_TEMPLATE:
      return {
        ...state,
        scheduleList: action.payload,
      }

    case TOGGLE_SCHEDULE_TEMPLATE_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state
  }
}

export default scheduleTemplateReducer;
